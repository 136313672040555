<template>
	<v-card class="mb-2">
		<v-list>
			<v-list-item
				v-for="issue in sortByLatestChanged"
				:key="issue.id"
				:to="{ name: 'issue', params: { id: issue.id } }"
				two-line
			>
				<v-list-item-content class="issue-nr">
					<v-list-item-subtitle>Nr</v-list-item-subtitle>
					<v-list-item-title>{{ issue.number | shorten }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-content class="pr-1">
					<v-list-item-subtitle>Titel</v-list-item-subtitle>
					<v-list-item-title>{{ issue.subject }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-content class="issue-updated">
					<v-list-item-subtitle>Ändrad</v-list-item-subtitle>
					<v-list-item-title>
						{{ (issue.modified ? issue.modified : issue.created) | dateTime }}
					</v-list-item-title>
				</v-list-item-content>

				<v-list-item-icon class="mt-auto mb-auto pl-1 ml-1">
					<v-badge :value="$_commentMixin_hasUnreadComments(issue)" overlap dot>
						<v-icon>mdi-arrow-right</v-icon>
					</v-badge>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';
import commentMixin from '@/mixins/commentMixin';

export default {
	filters: {
		dateTime(dateTime) {
			// Checking datetime because sometimes we recieve
			// issues without created or modified dates.
			if (!dateTime) {
				return '';
			}

			const { date, time } = dateTime;
			const width = window.innerWidth;
			let format = 'd/M';
			if (width > 370 && width <= 450) {
				format = 'yyyy-MM-dd';
			} else if (width > 450) {
				format = 'yyyy-MM-dd HH:mm';
			}

			return spacetime(`${date}T${time}`, 'Europe/Stockholm').unixFmt(format);
		},
		shorten(number) {
			if (window.innerWidth >= 420) {
				return number;
			}

			const numberString = number.toString();
			if (numberString.length > 6) {
				return numberString.replace(/^\d+(\d{4})$/, '...$1');
			}

			return number;
		},
	},
	mixins: [commentMixin],
	props: {
		issues: {
			type: Array,
			required: true,
		},
	},
	computed: {
		sortByLatestChanged() {
			return this.issues.slice(0).sort(function(a, b) {
				const aDateTime = a.modified ? a.modified : a.created;
				const bDateTime = b.modified ? b.modified : b.created;

				if (!aDateTime) return 1;
				if (!bDateTime) return -1;

				const aSpacetime = spacetime(
					`${aDateTime.date}T${aDateTime.time}`,
					'Europe/Stockholm',
				);
				const bSpacetime = spacetime(
					`${bDateTime.date}T${bDateTime.time}`,
					'Europe/Stockholm',
				);
				if (aSpacetime.isBefore(bSpacetime)) {
					return 1;
				}

				if (bSpacetime.isBefore(aSpacetime)) {
					return -1;
				}

				return 0;
			});
		},
	},
};
</script>

<style>
.issue-nr {
	min-width: 50px;
	max-width: 58px;
}
.issue-updated {
	min-width: 47px;
	max-width: 47px;
}
@media screen and (min-width: 370px) and (max-width: 419px) {
	.issue-nr {
		min-width: 50px;
		max-width: 60px;
	}
	.issue-updated {
		min-width: 90px;
	}
}
@media screen and (min-width: 420px) {
	.issue-nr {
		min-width: 100px;
	}
	.issue-updated {
		min-width: 127px;
		max-width: 127px;
	}
}
</style>
