var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column flex-nowrap align-self-start"},[_c('apollo-query',{attrs:{"query":_vm.query,"update":function (data) { return data.issues; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var data = ref_result.data;
return [(data && data.length > 0)?[(_vm.activeIssues(data).length > 0)?[_c('v-subheader',{staticClass:"title"},[_vm._v("Aktiva dialoger")]),_c('a-issues-card',{attrs:{"issues":_vm.activeIssues(data)}})]:_vm._e(),(_vm.completedIssues(data).length > 0)?[_c('v-subheader',{staticClass:"title"},[_vm._v("Avslutade dialoger")]),_c('a-issues-card',{attrs:{"issues":_vm.completedIssues(data)}})]:_vm._e()]:(data && data.length === 0)?[_c('v-subheader',{staticClass:"title"},[_vm._v("Du har inga meddelanden")])]:[_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item-two-line@2"}}),_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item-two-line@2"}})]]}}])}),_c('apollo-query',{attrs:{"query":_vm.fabQuery,"update":function (data) { return data.issueTypes; },"variables":{ input: { view: 'issues' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var data = ref_result.data;
return [(data)?_c('a-create-issue-fab',{attrs:{"issue-types":data,"title":""}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }