<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query :query="query" :update="data => data.issues">
			<template #default="{ result: { loading, data } }">
				<template v-if="data && data.length > 0">
					<template v-if="activeIssues(data).length > 0">
						<v-subheader class="title">Aktiva dialoger</v-subheader>
						<a-issues-card :issues="activeIssues(data)" />
					</template>

					<template v-if="completedIssues(data).length > 0">
						<v-subheader class="title">Avslutade dialoger</v-subheader>
						<a-issues-card :issues="completedIssues(data)" />
					</template>
				</template>

				<template v-else-if="data && data.length === 0">
					<v-subheader class="title">Du har inga meddelanden</v-subheader>
				</template>

				<template v-else>
					<a-loading
						:loading="loading"
						type="card-heading, list-item-two-line@2"
					/>

					<a-loading
						:loading="loading"
						type="card-heading, list-item-two-line@2"
					/>
				</template>
			</template>
		</apollo-query>

		<apollo-query
			:query="fabQuery"
			:update="data => data.issueTypes"
			:variables="{ input: { view: 'issues' } }"
		>
			<template #default="{ result: { loading, data } }">
				<a-create-issue-fab v-if="data" :issue-types="data" title />
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import ALoading from '@/components/Loading';
import ACreateIssueFab from '@/components/CreateIssueFab';
import AIssuesCard from '@/components/IssuesCard';
import { ISSUES, ISSUE_CREATION_TYPES } from '@/graphql';

export default {
	name: 'Issues',
	components: {
		ALoading,
		ACreateIssueFab,
		AIssuesCard,
	},
	data: () => ({
		query: ISSUES,
		fabQuery: ISSUE_CREATION_TYPES,
	}),
	methods: {
		activeIssues(issues) {
			return issues.filter(issue => issue.active);
		},
		completedIssues(issues) {
			return issues.filter(issue => !issue.active);
		},
	},
	metaInfo: {
		title: 'Inkorg',
	},
};
</script>

<style scoped></style>
